<template>
  <hit-card
    class="border border-table flex flex-col cursor-pointer w-full px-2 pt-1 mb-4"
    :display-shadow="false"
  >
    <div
      v-for="itemProperty in itemPropertiesToDisplay"
      :key="itemProperty.propertyName"
      class="hit-form-row"
    >
      <div class="font-semibold pb-1">
        {{ itemProperty.header }} :
      </div>
      <div class="mb-2">
        <slot
          :name="generateCustomSlotName(itemProperty.propertyName)"
          :form-data="item"
          :item-property="itemProperty"
          :validation-state="{}"
        >
          <div>
            <hit-input-generic-read-only
              :value="item[itemProperty.propertyName]"
              :value-type="getValueType(itemProperty.propertyName, item)"
            />
          </div>
        </slot>
      </div>
    </div>
    <div
      v-if="deletable"
      class="mb-2"
    >
      <hit-button
        prefix-icon="trash"
        color="danger"
        @click="deleteItem"
      />
    </div>
  </hit-card>
</template>
<script>
import {HitCard} from '../card';
import {HitInputGenericReadOnly} from '../form';
import HitTableDataMixin from './HitTableDataMixin';
import {HitButton} from '../button';
export default {
  name: 'HitTableDataListDynamicCard',
  components: {HitInputGenericReadOnly, HitCard, HitButton},
  mixins: [HitTableDataMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemPropertiesToDisplay: {
      type: Array,
      default: () => [],
    },
    deletable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['deleteItem'],
  methods: {
    deleteItem() {
      this.$emit('deleteItem', this.item);
    },
  },
};
</script>
